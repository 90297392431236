<style scoped lang="less">
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #b8d4f0 !important;
}
.main-content {
  background: #eff0f7;
  .record-button-left {
    width: 54%;
  }
  .record-button-right {
    width: 45%;
    display: flex;
    align-items: center;
    .search-input {
      width: 200px;
    }
  }
  .table-box {
    margin-top: 10px;
    margin-left: 10px;
    border-top-left-radius: 8px;
    /deep/.el-card__body {
      padding: 0;
      display: flex;
      height: calc(100vh - 6.5rem);
    }
    .left-box {
      width: 54%;
      height: 100%;
      .left-box-table {
        height: 48%;
        .report-table {
          border-top: none;
          border-left: none;
          border-right: none;
          /deep/ .el-table .cell {
            .table-data-picker {
              .el-date-editor {
                width: 170px;
              }
            }
          }
        }
      }
      .left-box-tabs {
        height: 52%;
        .left-box-header {
          display: flex;
          height: 36px;
          align-items: center;
          margin-left: 10px;
          justify-content: space-between;
        }
        .left-box-Btn {
          height: 2rem;
          line-height: 2rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .medicine-table {
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
    }

    .medicine-box {
      width: 46%;
      height: calc(100vh - 6.5rem);
      .inner-content-right {
        width: 100%;
        height: 100%;
        .inner-tabs-right {
          height: calc(100% - 4px);
          /deep/ .el-tabs__content {
            padding: 0;
            height: calc(100% - 2.25rem);
          }
        }
        .custom-tree-node {
          font-size: 13px;
          .key-node {
            color: #9b59b6;
          }
          .normal {
            color: #000;
          }
        }
        .el-pagination {
          text-align: center;
        }
      }
    }
  }

  .el-table .cell {
    padding: 0;
  }

  .add-form {
    .dialog-btn-wrap {
      height: 50px;
      width: 100%;
      background-color: #f2f2f2;
      z-index: 100;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .form-wrap {
      padding: 20px;
      .medicine-num {
        display: flex;
        align-items: center;
        width: 300px;
        height: 30px;
        span {
          width: 80px;
        }
        .el-input {
          width: 200px;
        }
      }
    }

    /deep/.el-dialog {
      background: #f8f8f8;

      .el-dialog__header {
        background: #2d507e;
        border: #2d507e;
        padding: 10px;
        .el-dialog__title {
          color: #fff;
        }
        .el-dialog__close {
          color: #fff;
        }
        .el-dialog__headerbtn {
          top: 0.9rem;
        }
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
}
</style>
<template>
  <div
    class="main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div v-show="!isReadOnly" class="crt-content">
      <div class="record-button-left">
        <el-button
          v-show="!isReadOnly"
          type="danger"
          class="common-button commonBtn"
          icon="el-icon-delete"
          v-debounce="open_deletePreRecord"
        >
          删除
        </el-button>
      </div>
      <div class="record-button-right">
        <el-button
          v-show="!isReadOnly"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-sort"
          @click="isInfo = !isInfo"
        >
          {{ isInfo ? '切换到用药套餐' : '切换到药品' }}
        </el-button>
        <div v-show="isInfo">
          <div style="display: flex">
            <el-button
              v-show="!isReadOnly"
              type="success"
              class="common-button commonBtn"
              icon="el-icon-edit"
              @click="handleModAdd"
            >
              领取药品
            </el-button>
            <div class="search-input">
              <el-input v-model="searchValue" placeholder="输入关键字进行搜索" clearable></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-card class="table-box">
      <div class="left-box">
        <div class="left-box-table">
          <el-table
            class="report-table"
            :data="reportData"
            ref="reportDataInfo"
            :header-cell-style="{ padding: 0 }"
            border
            height="100%"
            style="width: 100%"
            highlight-current-row
            @current-change="handleCurrentChange"
          >
            <el-table-column
              prop="content"
              label="报告内容"
              show-overflow-tooltip
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              label="执行者"
              width="150"
              align="center"
              prop="executeName"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-select
                  v-if="scope.row.executeNameIsShowEdit"
                  v-model="scope.row.oldExecuteName"
                  multiple
                  size="mini"
                  placeholder="请选择执行者"
                  @change="saveExecuteNameReportRow(scope.row)"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span v-else @click="editExecuteNameReportRow(scope.row)">
                  {{ scope.row.executeName }}
                </span>
              </template>
            </el-table-column>

            <el-table-column prop="executeDate" label="执行时间" width="190" align="center">
              <template slot-scope="scope">
                <div class="table-data-picker">
                  <el-date-picker
                    v-if="scope.row.executeDateIsShowEdit"
                    v-model="scope.row.oldExecuteDate"
                    type="datetime"
                    align="center"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    @change="saveExecuteDateReportRow(scope.row)"
                    @blur="initReportRow"
                  ></el-date-picker>
                  <span v-else @click="editExecuteDateReportRow(scope.row)">
                    {{ scope.row.executeDate?.substring(0, 16) }}
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="left-box-tabs">
          <div class="left-box-header">
            <div style="display: flex; align-items: center">
              <i class="el-icon-setting">{{ ' ' + leftTableTitle }}</i>
              <el-button
                v-show="!isReadOnly"
                type="success"
                class="common-button commonBtn"
                icon="el-icon-sort"
                @click="handleMedicineSort"
              >
                切换为{{ leftBtnTitle }}
              </el-button>
              <el-checkbox-group
                v-show="!isDrugInfo"
                style="display: flex; padding-left: 5px"
                v-model="headerTitle"
                @change="batchOperate"
              >
                <el-checkbox label="术前带药">术前带药</el-checkbox>
                <el-checkbox label="本地用药">本地用药</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="left-box-Btn" v-show="!isDrugInfo && this.selectTotalRowList?.length !== 0">
              <el-button
                v-show="!isReadOnly"
                type="success"
                class="common-button commonBtn"
                icon="el-icon-edit"
                @click="handleAddMecineList"
              >
                用药
              </el-button>
            </div>
          </div>
          <div style="height: calc(100% - 2.25rem)" v-show="!isDrugInfo">
            <el-table
              class="medicine-table"
              :header-cell-style="{ padding: 0 }"
              :data="medicineDataTotal"
              style="width: 100%"
              ref="multipleTotalTable"
              @sort-change="sortChange"
              @row-click="changeCurrentTotalRow"
              @selection-change="handleSelectionTotalChange"
              height="100%"
              border
            >
              <el-table-column type="selection" width="45"></el-table-column>
              <el-table-column
                header-align="center"
                label="套餐/药品名称"
                align="center"
                min-width="220"
                show-overflow-tooltip
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.medicineTitle">{{ scope.row.medicineTitle }}</span>
                  <span v-if="scope.row.packageTitle">{{ scope.row.packageTitle }}</span>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="来源" align="center" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.medicineSource === 'INTRAOPERATIVE'">本地用药</span>
                  <span v-if="scope.row.medicineSource === 'PREOPERATIVE'">术前带药</span>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="数量"
                width="90"
                sortable="custom"
                prop="num"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    class="common-button commonBtn"
                    type="success"
                    style="margin: 0 10px 0 0"
                    @click="handleAddMecine(scope.row)"
                  >
                    <div style="display: flex">
                      <i class="el-icon-finished"></i>
                      <div>用药</div>
                    </div>
                  </el-button>
                  <el-button
                    size="mini"
                    class="common-button commonBtn"
                    type="danger"
                    style="margin: 0"
                    @click="handleDel(scope.row)"
                  >
                    <div style="display: flex">
                      <i class="el-icon-finished"></i>
                      删除
                    </div>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div style="height: calc(100% - 2.25rem)" v-show="isDrugInfo">
            <el-table
              class="medicine-table"
              :header-cell-style="{ padding: 0 }"
              :data="medicineStatistics"
              style="width: 100%"
              @sort-change="sortChangeMedicine"
              height="100%"
              border
            >
              <el-table-column
                header-align="center"
                label="药品名称"
                align="center"
                min-width="220"
                show-overflow-tooltip
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.medicineName }}</span>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="来源" align="center" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.medicineSource === 'INTRAOPERATIVE'">本地用药</span>
                  <span v-if="scope.row.medicineSource === 'PREOPERATIVE'">术前带药</span>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="总数"
                width="90"
                sortable="custom"
                prop="total"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <div class="medicine-box">
        <div class="inner-content-right">
          <el-tabs v-show="!isInfo" class="inner-tabs-right" type="border-card">
            <el-tab-pane
              v-for="(item, index) in medicationData"
              :key="item.uuid"
              :label="item.groupTitle"
            >
              <el-tree
                :props="defaultProps"
                :data="medicationTreeData[index].content"
                @current-change="handleCheckChangeMedication"
                :default-expand-all="true"
                highlight-current
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span :class="data.packageId ? 'key-node' : 'normal'">{{ data.label }}</span>
                </span>
              </el-tree>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-show="isInfo"
            class="medicine-table"
            :header-cell-style="{ padding: 0 }"
            :data="allMedicineData"
            style="width: 100%"
            row-key="uuid"
            @sort-change="sortChangeAllMedicine"
            ref="multipleAllMedicineTable"
            height="calc(100% - 44px)"
            @row-click="changeAllMedicineCurrentRow"
            @selection-change="handleAllMedicineSelectionChange"
            border
          >
            <el-table-column align="center" type="index" width="30"></el-table-column>
            <el-table-column
              type="selection"
              :reserve-selection="true"
              width="45"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="药品名称"
              align="center"
              width="180"
              show-overflow-tooltip
              sortable="custom"
              prop="medicineName"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="规格"
              align="center"
              sortable="custom"
              prop="medicineDose"
              width="100"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              label="单价"
              align="center"
              width="100"
              sortable="custom"
              prop="price"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="生产厂家"
              width="150"
              show-overflow-tooltip
              align="center"
              prop="manufacturer"
            ></el-table-column>
            <el-table-column
              header-align="center"
              label="批准文号"
              align="center"
              width="180"
              prop="medicineCode"
            ></el-table-column>
          </el-table>
          <el-pagination
            v-show="isInfo"
            @size-change="handleSizeChangeallMedicine"
            @current-change="handleCurrentChangeallMedicine"
            :current-page="medicineCurrent"
            :page-sizes="[15, 30]"
            :page-size="medicineSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="medicineTotal"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <!-- <ListContentDialog ref="listContentDialog" @pickedObjectEmit="changeData"></ListContentDialog> -->
    <AddPackageMedicationDialog
      ref="AddPackageMedicationDialog"
      @pickedObjectEmit="changeData"
    ></AddPackageMedicationDialog>
    <AddMedicationDialog
      ref="AddMedicationDialog"
      @pickedObjectEmit="changeData"
    ></AddMedicationDialog>
    <EditMedicationDialog
      ref="EditMedicationDialog"
      @pickedObjectEmit="changeData"
    ></EditMedicationDialog>
    <!-- <PreMedication ref="preMedication" @save="_dialogSave"></PreMedication> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import ListContentDialog from './components/ListContentDialog.vue'
import AddPackageMedicationDialog from './components/AddPackageMedicationDialog.vue'
import AddMedicationDialog from './components/AddMedicationDialog'
import EditMedicationDialog from './components/EditMedicationDialog.vue'
// import PreMedication from './components/PreMedication.vue'
import CqtInput from '../../../components/cqt/CqtInput.vue'

export default {
  name: 'Medicate',
  components: {
    // ListContentDialog,
    AddMedicationDialog,
    AddPackageMedicationDialog,
    EditMedicationDialog,
    // PreMedication,
    CqtInput
  },
  data() {
    return {
      reportData: [],
      options: [],
      medicationData: [],
      medicationTreeData: [],
      medicationTreeClickCount: 0,
      defaultProps: {
        children: 'items',
        label: 'label'
      },
      headerTitle: ['本地用药'],
      source: 'INTRAOPERATIVE',
      currentRow: null,
      // 是否可编辑
      isReadOnly: false,
      loading: false,
      isInfo: false,
      // 用药记录
      allMedicineData: [],
      medicineDataTotal: [],
      medicineStatistics: [],
      selectTotalRowList: [],
      selectRowListDrug: [],
      searchValue: '',
      medicineCurrent: 1,
      medicineSize: 30,
      medicineTotal: 0,
      leftTableTitle: '领药流水',
      leftBtnTitle: '药品汇总',
      isDrugInfo: false
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    currentPatient: {
      handler(val) {
        if (val && val.uuid) {
          this.getListData()
          this.getMedicineData()
          this.getMedicationData()
          this.getAllMedicineData()
        }
      },
      immediate: true
    },
    searchValue: {
      handler() {
        this.debounce(this.getAllMedicineData, 500)
      }
    }
  },
  methods: {
    batchOperate(val) {
      if (val && val.length === 1) {
        for (let i = 0; i < val.length; i++) {
          const ele = val[i]
          if (ele === '术前带药') {
            this.source = 'PREOPERATIVE'
          }
          if (ele === '本地用药') {
            this.source = 'INTRAOPERATIVE'
          }
        }
      } else {
        this.source = ''
      }
      if (this.isDrugInfo === true) {
        this.getMedicineStatistics()
      } else {
        this.getMedicineData()
      }
    },

    sortChange({ prop, order }) {
      this.medicineDataTotal.sort(this.compare(prop, order))
    },

    sortChangeMedicine({ prop, order }) {
      this.medicineStatistics.sort(this.compare(prop, order))
    },

    sortChangeAllMedicine({ prop, order }) {
      this.allMedicineData.sort(this.compare(prop, order))
    },

    compare(propertyName, sort) {
      function isNumberV(val) {
        var regPos = /^\d+(\.\d+)?$/ //非负浮点数
        var regNeg =
          /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ //负浮点数
        if (regPos.test(val) || regNeg.test(val)) {
          return true
        } else {
          return false
        }
      }

      return function (obj1, obj2) {
        let value1 = obj1[propertyName]
        let value2 = obj2[propertyName]
        // 数字类型的比较
        if (isNumberV(value1) || isNumberV(value2)) {
          if (sort === 'ascending') {
            return value1 - value2
          } else {
            return value2 - value1
          }
        }
        // 字符比较使用localeCompare()
        else {
          if (value1 && value2) {
            const res = value1.localeCompare(value2, 'zh')
            return sort === 'ascending' ? res : -res
          }
        }
      }
    },

    debounce(fn, wait) {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.medicineCurrent = 1
      this.timer = setTimeout(fn, wait)
    },

    handleSizeChangeallMedicine(newSize) {
      this.medicineSize = newSize
      this.getAllMedicineData()
    },
    handleCurrentChangeallMedicine(newPage) {
      this.medicineCurrent = newPage
      this.getAllMedicineData()
    },
    handleSelectionTotalChange(val) {
      this.selectTotalRowList = val
    },
    handleAllMedicineSelectionChange(val) {
      this.selectRowListDrug = val
    },

    // handleDisable(row) {
    //   if (row.package === true) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },

    changeCurrentTotalRow(row) {
      if (row) {
        this.$refs.multipleTotalTable.toggleRowSelection(row)
        // if (row.package !== true) {
        //   this.$refs.multipleTotalTable.toggleRowSelection(row)
        // }
      } else {
        this.$refs.multipleTotalTable.clearSelection()
      }
    },

    changeAllMedicineCurrentRow(row) {
      if (row) {
        this.$refs.multipleAllMedicineTable.toggleRowSelection(row)
      } else {
        this.$refs.multipleAllMedicineTable.clearSelection()
      }
    },

    handleCheckChangeMedication(val) {
      this.medicationTreeClickCount++
      if (this.medicationTreeClickCount >= 2) {
        return
      }
      this.timer = window.setTimeout(() => {
        if (this.medicationTreeClickCount == 1) {
          this.medicationTreeClickCount = 0
          return
        } else if (this.medicationTreeClickCount > 1) {
          //把次数归零
          this.medicationTreeClickCount = 0
          if (this.isReadOnly) {
            return this.$message.warning('不可操作！')
          }
          //双击事件
          var time = new Date()
          time.setHours(time.getHours() + 8)
          time = time.toISOString()
          time = time.replace('T', ' ')
          time = time.substr(0, 19)

          this.$refs.AddPackageMedicationDialog.Show(val)
        }
      }, 300)
    },

    handleAddMecine(val) {
      let arr = []
      arr.push(val)
      if (val.medicine === true) {
        val.medicineNum = ''
      }
      this.$refs.EditMedicationDialog.Show(arr)
    },
    handleAddMecineList() {
      if (this.selectTotalRowList.length > 0) {
        this.selectTotalRowList.forEach((item) => {
          if (item.medicine === true) {
            item.medicineNum = ''
          }
        })
        this.$refs.EditMedicationDialog.Show(this.selectTotalRowList)
      } else {
        this.$message.warning('请选择药品')
      }
    },

    handleModAdd() {
      if (this.selectRowListDrug.length > 0) {
        this.$refs.AddMedicationDialog.Show(this.selectRowListDrug)
      } else {
        return this.$message.warning('请选择要添加至报告的药品')
      }
    },

    handleMedicineSort() {
      if (this.isDrugInfo === true) {
        this.leftTableTitle = '领药流水'
        this.leftBtnTitle = '药品汇总'
      } else {
        this.leftTableTitle = '药品汇总'
        this.leftBtnTitle = '领药流水'
        this.selectTotalRowList = null
        this.$refs.multipleTotalTable.clearSelection()
        this.getMedicineStatistics()
      }
      this.isDrugInfo = !this.isDrugInfo
    },

    handleDel(val) {
      this.$api.deleteObj(`/v1/webconsole/medication/medication/delete/${val.uuid}`).then(
        () => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getMedicineData()
          this.getListData()
        },
        () => {}
      )
    },

    getAllMedicineData() {
      let url =
        '/v1/webconsole/medication/medicines/list?' +
        (this.medicineCurrent ? 'current=' + this.medicineCurrent : '') +
        (this.medicineSize ? '&size=' + this.medicineSize : '') +
        (this.searchValue ? '&keyword=' + this.searchValue : '')
      this.$api.get(url).then((res) => {
        this.medicineTotal = res.data.data.total
        if (this.selectRowListDrug && this.selectRowListDrug.length > 0) {
          let arr = [...this.selectRowListDrug, ...res.data.data.records]
          for (let i = 0; i < arr.length; i++) {
            for (let j = i + 1; j < arr.length; j++) {
              if (arr[i].uuid === arr[j].uuid) {
                arr.splice(j, 1)
                j-- // 保证j的值自加后不变
              }
            }
          }
          this.allMedicineData = arr
          for (let i = 0; i < this.allMedicineData.length; i++) {
            const element = this.allMedicineData[i]
            for (let j = 0; j < this.selectRowListDrug.length; j++) {
              const ele = this.selectRowListDrug[j]
              if (element.uuid === ele.uuid) {
                this.$refs.multipleAllMedicineTable.toggleRowSelection(element, true)
              }
            }
          }
        } else {
          this.allMedicineData = res.data.data.records
        }
      })
    },

    getMedicineStatistics() {
      this.$api
        .get(
          `/v1/webconsole/medication/medication/statistics/source/${this.$route.query.operationId}`
        )
        .then(
          (res) => {
            if (res.data && res.data.data) {
              this.medicineStatistics = res.data.data
            }
          },
          () => {}
        )
    },

    getMedicineData() {
      this.$api
        .get(
          `/v1/webconsole/medication/medication/list/${this.$route.query.operationId}?source=${this.source}`
        )
        .then(
          (res) => {
            if (res.data && res.data.data) {
              this.medicineDataTotal = res.data.data
            }
          },
          () => {}
        )
    },

    changeData() {
      this.selectRowListDrug = null
      this.selectTotalRowList = null
      this.$refs.multipleAllMedicineTable.clearSelection()
      this.getListData()
      this.getMedicineData()
      this.getMedicationData()
      this.getAllMedicineData()
    },
    getListData() {
      this.loading = true
      if (this.currentPatient) {
        this.$api.get(`/v1/webconsole/medication/report/${this.$route.query.operationId}`).then(
          (res) => {
            this.loading = false
            if (res.data && res.data.data) {
              this.reportData = res.data.data.map((item) => {
                return {
                  ...item,
                  executeNameIsShowEdit: false,
                  executeDateIsShowEdit: false,
                  oldExecuteName: [],
                  oldExecuteDate: item.executeDate
                }
              })
              this.getNurses()
            }
          },
          (err) => {
            this.loading = false
            console.log(err)
          }
        )
      }
    },

    editExecuteNameReportRow(val) {
      this.reportData.forEach((row) => {
        row.executeNameIsShowEdit = false
        row.executeDateIsShowEdit = false
      })
      if (val.executeName.includes(',')) {
        val.oldExecuteName = val.executeName.split(',')
      } else {
        val.oldExecuteName = []
        val.oldExecuteName.push(val.executeName)
      }
      val.executeNameIsShowEdit = !val.executeNameIsShowEdit
    },

    editExecuteDateReportRow(val) {
      this.reportData.forEach((row) => {
        row.executeDateIsShowEdit = false
        row.executeNameIsShowEdit = false
      })
      val.executeDateIsShowEdit = !val.executeDateIsShowEdit
    },

    initReportRow() {
      this.reportData.forEach((row) => {
        row.executeDateIsShowEdit = false
      })
    },

    saveExecuteNameReportRow(val) {
      let formData = new FormData()
      if (val.oldExecuteName.length > 0) {
        if (val.oldExecuteName.length === 1) {
          formData.append('executeNames', val.oldExecuteName[0])
        } else {
          formData.append('executeNames', val.oldExecuteName.join(','))
        }
      } else {
        return this.$message.warning('请选择执行者')
      }
      formData.append('executeTime', val.executeDate)

      this.$api
        .post(`/v1/webconsole/nursing/report/update/${val.reportIndex}`, formData)
        .then(() => {
          this.$message.success('保存成功')
          this.getListData()
        })

      val.executeNameIsShowEdit = !val.executeNameIsShowEdit
    },
    saveExecuteDateReportRow(val) {
      let formData = new FormData()
      if (val.oldExecuteDate) {
        formData.append('executeTime', val.oldExecuteDate)
      } else {
        return this.$message.warning('请选择执行时间')
      }
      formData.append('executeNames', val.executeName)
      this.$api
        .post(`/v1/webconsole/nursing/report/update/${val.reportIndex}`, formData)
        .then(() => {
          this.$message.success('保存成功')
          this.getListData()
        })

      val.executeDateIsShowEdit = !val.executeDateIsShowEdit
    },
    getNurses() {
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userName
              })
            })
            this.options = arr
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },

    _dialogSave() {
      this.getMedicineData()
      this.getListData()
    },

    open_deletePreRecord() {
      if (!this.currentRow) return this.$message.warning('请选择一项报告')
      this.$confirm('此操作将删除该条报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deletePreRecord()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    deletePreRecord() {
      this.$api
        .delete(`/v1/webconsole/medication/report/delete/${this.currentRow.reportIndex}`)
        .then(
          () => {
            this.reportData = this.reportData.filter(
              (item) => item.reportIndex !== this.currentRow.reportIndex
            )
            this.currentRow = null
            this.$message.success('删除成功')
            this.changeData()
          },
          (error) => console.log(error)
        )
    },

    trans(arr) {
      for (let obj of arr) {
        obj['label'] = obj['title']
        obj['items'] = null
      }
      return arr
    },

    getMedicationData() {
      this.$api.get(`/v1/webconsole/medication/group/OPERATION`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.medicationData = res.data.data
            for (let i = 0; i < this.medicationData.length; i++) {
              this.medicationTreeData.push({
                content: []
              })
            }
            const promise = res.data.data.map(async (item) => {
              let url = `/v1/webconsole/medication/packages/${item.uuid}`
              const res = await this.$api.get(url)
              return res.data.data
            })
            Promise.all(promise).then((result) => {
              result.map((item, index) => {
                if (item.length > 0) {
                  this.medicationTreeData[index].content = this.trans([...item])
                }
              })
            })
          }
        },
        () => {}
      )
    }
  }
}
</script>
