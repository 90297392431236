<style scoped lang="less">
.selected-text {
  background-color: #f0f0f0;
  margin: 10px 10px 0;
  .infoTable-medicine {
    height: 15rem;
  }
  .infoTable {
    height: 12rem;
  }
}
.infoText {
  height: 2rem;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;

  .infoTextContent {
    color: red;
    margin-left: 10px;
  }
  .infoTextInput {
    display: flex;
    align-items: center;
    .el-input {
      width: 60px;
    }
    .el-icon-circle-close {
      margin-left: 5px;
    }
  }
  .el-icon-circle-close {
    color: red;
    cursor: pointer;
    font-size: 16px;
  }

  /deep/.el-input-group__append {
    background-color: beige;
  }
  /deep/ .el-input-group__prepend {
    color: red;
    background-color: #fff;
  }
  /deep/.el-input-group--append .el-input__inner {
    border-radius: 5%;
    margin-right: 5px;
  }
}
.except-text {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  margin: 0 -0.625rem;
  padding: 0 0.625rem;
}
.demonstration {
  margin: 10px 0 0 10px;
}
.infoTitle {
  margin-left: 10px;
}

.crt-content-center {
  padding-top: 16px;
  justify-content: space-around;
}
.space {
  background-color: #f0f0f0;
  height: 1.5rem;
  width: 100%;
}

/deep/ .dialog-content__horizontal {
  width: 60rem;
  height: 26rem;
}

/deep/ .el-table__row {
  .el-table__cell {
    .cell {
      min-height: 28px;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="用药详情"
    v-if="isShow"
    @close="handleCancel"
  >
    <div class="crt-content">
      <div class="infoTitle">
        <template>
          预览：
          <span v-for="(item, index) in input_list" :key="'list1' + index">
            <template v-if="item.package === true">
              <span v-for="(ele, index) in item.children">
                <span>{{ ele.medicineName }}</span>
                <span style="color: red">{{ ele.medicineNum }}</span>
                <span v-if="ele.medicineDoseUnit">{{ ele.medicineDoseUnit }}</span>
                &ensp;
              </span>
            </template>
            <template v-else>
              <span>{{ item.medicineTitle }}</span>
              <!-- <span style="color: red" v-if="item.medicineNum">({{ item.medicineNum }})</span> -->
              &ensp;
            </template>
          </span>
          <span v-for="(item, index) in inputList" :key="'list2' + index">
            <span>{{ item.pre }}</span>
            <span v-if="item.val !== null" style="color: red">{{ item.val }}</span>
          </span>
        </template>
      </div>
    </div>
    <div class="selected-text">
      <div class="infoTable">
        <el-table
          :data="input_list"
          :header-cell-style="{ padding: 0 }"
          border
          row-key="id"
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          style="width: 100%"
          height="12rem"
          highlight-current-row
        >
          <el-table-column label="药品/套餐" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.packageTitle" style="color: #9b59b6">
                {{ scope.row.packageTitle }}
              </div>
              <div v-if="scope.row.medicineTitle">{{ scope.row.medicineTitle }}</div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="来源" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.medicineSource === 'INTRAOPERATIVE'">本地用药</span>
              <span v-if="scope.row.medicineSource === 'PREOPERATIVE'">术前带药</span>
            </template>
          </el-table-column>
          <el-table-column label="用量" align="center" width="150">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.medicineNum !== undefined"
                placeholder="请输入"
                type="text"
                v-model="scope.row.medicineNum"
                size="mini"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="infoText" style="padding: 0 0.625rem; margin: 0 -0.625rem">
        <div class="infoTextContent">给药方式：</div>
        <div class="infoTextSelect" v-if="isSelect">
          <el-select
            v-model="useTypeId"
            filterable
            clearable
            size="mini"
            placeholder="请选择给药方式"
            @change="changeUseType"
          >
            <el-option
              v-for="item in textList"
              :key="item.value"
              :label="item.useTypeOld"
              :value="item.uuid"
            ></el-option>
          </el-select>
        </div>
        <div class="infoTextInput" v-else-if="!isSelect">
          <div v-for="(item, index) in inputList" :key="index">
            <div v-if="item.val !== null" style="display: flex; align-items: center">
              <div v-if="item.pre" style="color: red; flex-shrink: 0">{{ item.pre }}</div>
              <el-input
                style="margin-left: 5px; margin-right: 2px"
                v-model="item.val"
                placeholder=""
              ></el-input>
            </div>
            <div v-else style="color: red">{{ item.pre }}</div>
          </div>
          <i class="el-icon-circle-close" @click="showSelect"></i>
        </div>
      </div>
      <div class="except-text">
        <div class="executioner">
          <span class="demonstration">执行者（可多选、可搜索）</span>
          <el-select
            value-key="label"
            v-model="executeNames"
            multiple
            filterable
            size="mini"
            placeholder="请选择执行者"
            style="margin: 10px 0 0 10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="execution-time">
          <div class="block">
            <span class="demonstration">执行时间</span>
            <el-date-picker
              v-model="executeDate"
              type="datetime"
              align="center"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm"
              style="margin: 10px 0 0 10px"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="crt-content crt-content-center">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="handleSaveMedication"
      >
        保存
      </el-button>

      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="handleCancel"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>
<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'AddMedicationDialog',
  components: {
    CmDialogBorder
  },

  data() {
    return {
      input_list: [],
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      inputList: [],
      textList: [],
      f_flag: true,
      isSelect: true,
      options: [],
      source: 'INTRAOPERATIVE',
      stage: 'SZ',
      executeNames: [],
      useTypeId: '',
      useTypeValue: '',
      executeDate: null,
      tableData: []
    }
  },
  mounted() {
    this.getMedicationMethod()
  },
  methods: {
    getDate() {
      var time = new Date()
      time.setHours(time.getHours() + 8)
      time = time.toISOString()
      time = time.replace('T', ' ')
      time = time.substr(0, 19)
      this.executeDate = time
    },

    getExecuteNameList() {
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userName
              })
            })
            this.options = arr
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    getMedicationMethod() {
      this.$api.get(`/v1/webconsole/medication/usetype/list`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.textList = res.data.data
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    changeUseType(val) {
      for (let i = 0; i < this.textList.length; i++) {
        const element = this.textList[i]
        if (val === element.uuid) {
          const s = element.useTypeOld
          if (s.includes('|')) {
            this.getList(s)
          } else {
            this.inputList.push({
              pre: s,
              val: null
            })
          }
        }
      }
      this.isSelect = !this.isSelect
    },
    getList(s) {
      var k, i
      for (i = 0, k = 0; i < s.length; i++) {
        if (s[i] == '|' && i + 1 < s.length) {
          this.inputList.push({
            pre: s.substr(k, i - k),
            val: ''
          })
          k = i + 1
        } else {
          if (i + 1 === s.length) {
            this.inputList.push({
              pre: s.substr(k, s.length - k),
              val: null
            })
          }
        }
      }
    },
    Show(val) {
      this.getDate()
      this.getExecuteNameList()
      this.input_list = deepClone(val)
      this.input_list.forEach((item, index) => {
        if (item.package === true) {
          this.getMedicines(item, index)
        }
      })

      this.isShow = true
    },

    getMedicines(val, index) {
      val.id = val.uuid
      this.$api.get(`/v1/webconsole/medication/packages/medicines/${val.packageId}`).then(
        (res) => {
          if (res.data && res.data.data) {
            res.data.data.forEach((item) => {
              item.medicineTitle = item.medicineName
              item.medicineNum = item.consumption || ''
              item.medicineSource = val.medicineSource
              item.id = parseInt(Math.random(0, 1) * 100000000)
            })
            val.children = [...res.data.data]
            this.$set(this.input_list, index, val)
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    handleSaveMedication() {
      let medicineData = []

      this.inputList.forEach((item) => {
        if (item.val !== null) {
          this.useTypeValue = this.useTypeValue + '#' + item.val
          if (item.val === '') {
            this.f_flag = false
          }
        }
      })

      var arr = this.useTypeValue.split('#').filter(Boolean)
      this.input_list.forEach((item) => {
        let data = {
          medicationId: '',
          pkgs: [],
          useTypeId: '',
          useTypeArgs: {},
          executeNames: [],
          executeTime: ''
        }

        this.textList.forEach((item) => {
          if (this.useTypeId === item.uuid) {
            if (item.vars) {
              item.vars.forEach((v, index) => {
                data.useTypeArgs[v.varname] = arr[index]
              })
            }
          }
        })

        if (item.children) {
          let childrenArr = []
          item.children.forEach((v) => {
            childrenArr.push({ medicineId: v.medicineId, num: v.medicineNum })
          })
          data.pkgs = childrenArr
        } else {
          data.pkgs.push({ medicineId: item.medicineId, num: item.medicineNum })
        }

        data.medicationId = item.uuid
        data.useTypeId = this.useTypeId
        data.executeTime = this.executeDate
        data.executeNames = this.executeNames
        medicineData.push(data)
      })

      if (!this.f_flag) {
        this.f_flag = !this.f_flag
        return this.$message.error('有未填项,无法保存')
      }
      if (!this.useTypeId) {
        return this.$message.error('请选择给药方式')
      }
      if (this.executeNames.length === 0) {
        return this.$message.error('请选择执行者')
      }
      if (this.executeDate === '') {
        return this.$message.error('请填写执行时间')
      }

      this.$api
        .post(
          `/v1/webconsole/medication/medication/use/${this.$route.query.operationId}?stage=${this.stage}`,
          medicineData
        )
        .then(
          (res) => {
            if (res.data && res.status === 200) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('pickedObjectEmit')
            } else {
              this.$message({
                message: '保存失败',
                type: 'warning'
              })
            }
            this.handleCancel()
          },
          () => {
            this.$message({
              message: '保存失败',
              type: 'warning'
            })
          }
        )
    },
    showSelect() {
      this.useTypeId = ''
      this.inputList = []
      this.isSelect = !this.isSelect
    },
    handleCancel() {
      this.useTypeId = ''
      this.useTypeValue = ''
      this.executeDate = null
      this.inputList = []
      this.input_list = []
      this.executeNames = []
      this.isSelect = true
      this.isShow = false
    }
  },
  computed: {},
  watch: {}
}
</script>
