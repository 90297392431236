<style scoped lang="less">
.selected-text {
  background-color: #f0f0f0;
  margin: 10px 10px 0;
  .infoTable-medicine {
    height: 15rem;
  }
  .infoTable {
    height: 12rem;
  }
}
.infoText {
  height: 2rem;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  .infoTextContent-type {
    color: #000;
  }
  .infoTextContent {
    color: red;
    margin-left: 10px;
  }
  .infoTextInput {
    display: flex;
    align-items: center;
    .el-input {
      width: 60px;
    }
    .el-icon-circle-close {
      margin-left: 5px;
    }
  }
  .infoTextSelect-source {
    margin: 0 5px 0 10px;
    .el-select {
      width: 180px;
    }
  }
  .infoTextInput-medicine {
    display: flex;
    align-items: center;
    .el-input {
      width: 120px;
    }
  }
  .el-icon-circle-close {
    color: red;
    cursor: pointer;
    font-size: 16px;
  }

  /deep/.el-input-group__append {
    background-color: beige;
  }
  /deep/ .el-input-group__prepend {
    color: red;
    background-color: #fff;
  }
  /deep/.el-input-group--append .el-input__inner {
    border-radius: 5%;
    margin-right: 5px;
  }
}
.except-text {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  margin: 0 -0.625rem;
  padding: 0 0.625rem;
}
.demonstration {
  margin: 10px 0 0 10px;
}
.infoTitle {
  margin-left: 10px;
}
.content-btn {
  display: flex;
  align-items: center;
}
.crt-content-center {
  padding-top: 16px;
  justify-content: space-between;
}

/deep/ .dialog-content__horizontal {
  width: 60rem;
  height: 26rem;
}

/deep/ .el-table__row {
  .el-table__cell {
    .cell {
      min-height: 28px;
    }
  }
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="套餐详情"
    v-if="isShow"
    @close="handleCancel"
  >
    <div class="crt-content">
      <div class="infoTitle">
        <template v-if="isMedicine">
          预览：
          <span v-for="(item, index) in input_list" :key="'list1' + index">
            <span>{{ item.medicineName }}</span>
            <span style="color: red">{{ item.consumption }}</span>
            <span v-if="item.medicineDoseUnit">{{ item.medicineDoseUnit }}</span>
            &ensp;
          </span>
          <span v-for="(item, index) in inputList" :key="'list2' + index">
            <span>{{ item.pre }}</span>
            <span v-if="item.val !== null" style="color: red">{{ item.val }}</span>
          </span>
        </template>
        <template v-else>
          <div class="infoText" v-show="!isMedicine">
            <div class="infoTextContent">套餐数量：</div>
            <div class="infoTextInput-medicine">
              <el-input
                style="margin-left: 5px; margin-right: 2px"
                type="number"
                v-model="packageNum"
                placeholder=""
              ></el-input>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="selected-text">
      <div class="infoTable" v-show="isMedicine">
        <el-table
          :data="input_list"
          :header-cell-style="{ padding: 0 }"
          border
          style="width: 100%"
          height="12rem"
          highlight-current-row
        >
          <el-table-column label="药品" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="规格" align="center" width="80">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
            </template>
          </el-table-column>
          <el-table-column label="用量" align="center" width="150">
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                type="text"
                v-model="scope.row.consumption"
                size="mini"
              >
                <template v-if="scope.row.medicineDoseUnit" slot="append">
                  {{ scope.row.medicineDoseUnit }}
                </template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="infoTable-medicine" v-show="!isMedicine">
        <el-table
          :data="input_list"
          :header-cell-style="{ padding: 0 }"
          border
          style="width: 100%"
          height="15rem"
          highlight-current-row
        >
          <el-table-column label="药品" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="规格" align="center" width="80">
            <template slot-scope="scope">
              <div>{{ scope.row.medicineDose }}{{ scope.row.medicineDoseUnit }}</div>
            </template>
          </el-table-column>
          <el-table-column label="用量" align="center" width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.consumption }}{{ scope.row.medicineDoseUnit }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="infoText" v-show="isMedicine" style="padding: 0 0.625rem; margin: 0 -0.625rem">
        <div class="infoTextContent">给药方式：</div>
        <div class="infoTextSelect" v-if="isSelect">
          <el-select
            v-model="useTypeId"
            filterable
            clearable
            size="mini"
            placeholder="请选择给药方式"
            @change="changeUseType"
          >
            <el-option
              v-for="item in textList"
              :key="item.value"
              :label="item.useTypeOld"
              :value="item.uuid"
            ></el-option>
          </el-select>
        </div>
        <div class="infoTextInput" v-else-if="!isSelect">
          <div v-for="(item, index) in inputList" :key="index">
            <div v-if="item.val !== null" style="display: flex; align-items: center">
              <div v-if="item.pre" style="color: red; flex-shrink: 0">{{ item.pre }}</div>
              <el-input
                style="margin-left: 5px; margin-right: 2px"
                v-model="item.val"
                placeholder=""
              ></el-input>
            </div>
            <div v-else style="color: red">{{ item.pre }}</div>
          </div>
          <i class="el-icon-circle-close" @click="showSelect"></i>
        </div>
      </div>
      <div class="except-text" v-show="isMedicine">
        <div class="executioner">
          <span class="demonstration">执行者（可多选、可搜索）</span>
          <el-select
            value-key="label"
            v-model="executeNames"
            multiple
            filterable
            size="mini"
            placeholder="请选择执行者"
            style="margin: 10px 0 0 10px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="execution-time">
          <div class="block">
            <span class="demonstration">执行时间</span>
            <el-date-picker
              v-model="executeDate"
              type="datetime"
              align="center"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm"
              style="margin: 10px 0 0 10px"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="crt-content crt-content-center">
      <div class="content-btn" v-show="!isMedicine">
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="handleConfirm"
        >
          领取套餐药品
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="handlePreConfirm"
        >
          术前带药
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="handleIntraConfirm"
        >
          本地用药
        </el-button>
      </div>

      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="handleSaveMedication"
        v-show="isMedicine"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="handleCancel"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>
<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'AddPackageMedicationDialog',
  components: {
    CmDialogBorder
  },

  data() {
    return {
      input_list: [],
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      isMedicine: false,
      packageNum: 1,
      packageId: '',
      inputList: [],
      textList: [],
      f_flag: true,
      isSelect: true,
      options: [],
      source: 'INTRAOPERATIVE',
      stage: 'SZ',
      stageOptions: [
        {
          label: '术前',
          value: 'SQ'
        },
        {
          label: '术中',
          value: 'SZ'
        },
        {
          label: '术后',
          value: 'SH'
        }
      ],
      executeNames: [],
      useTypeId: '',
      useTypeValue: '',
      executeDate: null
    }
  },
  mounted() {
    this.getMedicationMethod()
  },
  methods: {
    getDate() {
      var time = new Date()
      time.setHours(time.getHours() + 8)
      time = time.toISOString()
      time = time.replace('T', ' ')
      time = time.substr(0, 19)
      this.executeDate = time
    },

    getExecuteNameList() {
      this.$api.get('/v1/webconsole/study/nurses').then(
        (res) => {
          if (res.data && res.data.data) {
            let arr = []
            res.data.data.map((item) => {
              arr.push({
                label: item.userName,
                value: item.userName
              })
            })
            this.options = arr
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    getMedicines(id) {
      this.$api.get(`/v1/webconsole/medication/packages/medicines/${id}`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.input_list = deepClone(res.data.data)
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },

    getMedicationMethod() {
      this.$api.get(`/v1/webconsole/medication/usetype/list`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.textList = res.data.data
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    changeUseType(val) {
      for (let i = 0; i < this.textList.length; i++) {
        const element = this.textList[i]
        if (val === element.uuid) {
          const s = element.useTypeOld
          if (s.includes('|')) {
            this.getList(s)
          } else {
            this.inputList.push({
              pre: s,
              val: null
            })
          }
        }
      }
      this.isSelect = !this.isSelect
    },
    getList(s) {
      var k, i
      for (i = 0, k = 0; i < s.length; i++) {
        if (s[i] == '|' && i + 1 < s.length) {
          this.inputList.push({
            pre: s.substr(k, i - k),
            val: ''
          })
          k = i + 1
        } else {
          if (i + 1 === s.length) {
            this.inputList.push({
              pre: s.substr(k, s.length - k),
              val: null
            })
          }
        }
      }
    },
    Show(val) {
      this.getDate()
      if (val && val.uuid) {
        this.packageId = val.uuid
        this.getMedicines(val.uuid)
        this.getExecuteNameList()
      }

      this.isMedicine = false
      this.isShow = true
    },
    handlePreConfirm() {
      this.isMedicine = true
      this.source = 'PREOPERATIVE'
    },
    handleIntraConfirm() {
      this.isMedicine = true
      this.source = 'INTRAOPERATIVE'
    },
    handleSaveMedication() {
      let data = {
        medicationPackageId: '',
        num: 1,
        pkgs: [],
        useTypeId: '',
        useTypeArgs: {},
        executeNames: [],
        executeTime: ''
      }
      this.inputList.forEach((item) => {
        if (item.val !== null) {
          this.useTypeValue = this.useTypeValue + '#' + item.val
          if (item.val === '') {
            this.f_flag = false
          }
        }
      })
      this.input_list.forEach((item) => {
        data.pkgs.push({ medicineId: item.medicineId, num: item.consumption })
      })
      var arr = this.useTypeValue.split('#').filter(Boolean)
      this.textList.forEach((item) => {
        if (this.useTypeId === item.uuid) {
          if (item.vars) {
            item.vars.forEach((v, index) => {
              data.useTypeArgs[v.varname] = arr[index]
            })
          }
        }
      })
      if (!this.f_flag) {
        this.f_flag = !this.f_flag
        return this.$message.error('有未填项,无法保存')
      }
      if (!this.useTypeId) {
        return this.$message.error('请选择给药方式')
      }
      if (this.executeNames.length === 0) {
        return this.$message.error('请选择执行者')
      }
      if (this.executeDate === '') {
        return this.$message.error('请填写执行时间')
      }

      data.medicationPackageId = this.packageId
      data.num = this.packageNum
      data.useTypeId = this.useTypeId
      data.executeTime = this.executeDate
      data.executeNames = this.executeNames
      this.$api
        .post(
          `/v1/webconsole/medication/medication/acceptuse/${this.$route.query.operationId}?stage=${this.stage}&source=${this.source}`,
          data
        )
        .then(
          (res) => {
            if (res.data && res.status === 200) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$emit('pickedObjectEmit')
            } else {
              this.$message({
                message: '保存失败',
                type: 'warning'
              })
            }
            this.handleCancel()
          },
          () => {
            this.$message({
              message: '保存失败',
              type: 'warning'
            })
          }
        )
    },
    handleConfirm() {
      let formData = new FormData()
      formData.append('source', this.source)
      formData.append('packageId', this.packageId)
      formData.append('num', this.packageNum)

      this.$api
        .post(
          `/v1/webconsole/medication/medication/add_package/${this.$route.query.operationId}`,
          formData
        )
        .then(
          () => {
            this.handleCancel()
            this.$emit('pickedObjectEmit')
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          },
          () => {
            this.$message({
              message: '保存失败',
              type: 'warning'
            })
          }
        )
    },
    showSelect() {
      this.useTypeId = ''
      this.inputList = []
      this.isSelect = !this.isSelect
    },
    handleCancel() {
      this.packageNum = 1
      this.packageId = ''
      this.useTypeId = ''
      this.useTypeValue = ''
      this.executeDate = null
      this.inputList = []
      this.input_list = []
      this.executeNames = []
      this.isSelect = true
      this.isShow = false
    }
  },

  computed: {},
  watch: {}
}
</script>
